import { FaPlay } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaArrowLeftLong } from "react-icons/fa6";
import React from "react";
import "../../Assets/scss/home1.scss";
import Navbar from "../../Components/Navbar";
import { Link } from "react-router-dom";

function Home1() {
  return (
    <>
      <nav>
        <div className="link">
          <Link to={"#"}>About</Link>
        </div>
        <div className="link">
          <Link to={"#"}>About</Link>
        </div>
        <div className="link">
          <Link to={"#"}>About</Link>
        </div>
        <div className="link">
          <Link to={"#"}>About</Link>
        </div>
        <div className="link">
          <Link to={"#"}>About</Link>
        </div>
        <div className="link">
          <Link to={"#"}>About</Link>
        </div>
      </nav>
      <div className="home1"></div>
      <div className="content">
        <img
          src={require("../../Assets/images/logo.png")}
          alt=""
          className="logo"
        />
        <h1>
          ORGANIZATIA STUDENTILOR DIN FACULTATEA DE INGINERIE INDUSTRIALA SI
          ROBOTICA
        </h1>
        <h3>
          Universitatea Națională de Știință și Tehnologie POLITEHNICA București
        </h3>
        <p>
          Some important details that I am way too lazy to write. Some important
          details that I am way too lazy to write.
        </p>
        <div className="circle">
          <FaArrowRightLong />
        </div>
        <div className="circle left">
          <FaArrowLeftLong />
        </div>
        <Link to={"#"} className="circle long">
          See more
        </Link>
      </div>
      <div className="about1">
        <div className="left"></div>
        <div className="right">
          <h1>DESPRE</h1>
          <h2>Despre</h2>
          <div className="line"></div>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem
            praesentium ipsa soluta molestias mollitia voluptatum sed nisi
            nesciunt sequi saepe, veniam dolore totam iusto culpa optio aut
            aliquid placeat exercitationem?
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem
            praesentium ipsa soluta molestias mollitia voluptatum sed nisi
            nesciunt sequi ?
          </p>
          <div className="buttons">
            <div className="circle">
              <FaPlay />
            </div>
            <div className="cirle long">Read More</div>
          </div>
        </div>
      </div>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem, sequi iste
        unde aut quam dolores tempore earum esse et odio iusto explicabo odit
        veritatis, facere sapiente ea quo sit ducimus non ut commodi cumque!
        Veniam aliquam, sint assumenda atque iste dolore blanditiis, eveniet
        repellat odio incidunt iure natus non exercitationem soluta cumque
        dolores voluptatem perferendis, dolorem consectetur doloremque id
        consequuntur in voluptatibus! Consectetur iste quasi at ab recusandae,
        quas necessitatibus cum, reiciendis similique minus perspiciatis
        consequatur. Provident fugit maiores voluptates aliquam nihil obcaecati
        impedit nisi ad voluptas cumque qui quasi eaque, velit quos facilis enim
        dolorum consequuntur placeat repellat ex. Incidunt, eos nesciunt atque
        eum officiis beatae debitis voluptas expedita reiciendis nulla alias
        harum fuga molestiae illo perferendis numquam omnis tenetur nam ipsam.
        Iure iusto repellendus voluptate alias quia eos pariatur, aperiam
        dolores ullam assumenda esse fugiat, obcaecati, totam et. Obcaecati,
        omnis consequatur tempore aspernatur maiores minima natus facere laborum
        perferendis? Rerum nisi enim quod voluptatum a! Nisi aliquam eum ex.
        Nihil eligendi et sed ex repudiandae provident, officia facilis ipsa
        velit, odit, explicabo ullam qui! Molestiae iste, aperiam eum modi
        tenetur dignissimos minima dolorum sint blanditiis nostrum non autem
        laboriosam culpa nesciunt explicabo placeat mollitia expedita aut
        facilis, esse fugit. Ex, veritatis cupiditate, aperiam sit accusamus hic
        optio quas unde odit saepe mollitia eveniet voluptatibus nesciunt
        dolorem facere quia cum autem itaque ad? Sapiente obcaecati inventore
        adipisci quod minima doloribus quos ipsa atque, odit quasi velit sed
        distinctio asperiores? Quae facilis vitae blanditiis, molestiae placeat
        minus eos nostrum laboriosam necessitatibus porro velit. Aut molestias
        tempora quidem quisquam distinctio obcaecati praesentium neque porro
        aperiam perspiciatis. Atque repellat debitis architecto ea corporis qui
        corrupti suscipit ducimus? Vero, vitae? Inventore doloremque aspernatur
        delectus nulla libero vero doloribus accusamus eaque commodi magni,
        quaerat modi cupiditate quas blanditiis sit soluta sequi dolorem
        voluptate quod nesciunt magnam quidem. Eum sed quaerat a eligendi beatae
        provident optio maxime nobis sunt, ipsum, iusto laboriosam quae
        aspernatur nostrum excepturi dolorum ipsam, voluptatum maiores quia
        mollitia ullam? Explicabo consectetur, facere vel, nesciunt recusandae
        omnis iste odio eum maiores doloribus quasi illum qui, expedita itaque.
        Dicta perspiciatis deserunt sapiente maxime obcaecati, ratione veniam
        rem praesentium tempora iste tenetur. Odio quas veritatis necessitatibus
        explicabo id, maxime nihil! Quae debitis unde aperiam sed. Autem
        consectetur, eaque provident odio nostrum explicabo nobis est recusandae
        fugit porro ipsum incidunt repudiandae? Atque quod, a consectetur
        molestias laudantium ipsam quos quidem officiis illo velit impedit
        ducimus, soluta ipsa quasi eaque repudiandae ab tenetur quaerat, dolor
        necessitatibus! Voluptatibus quod vero, nisi fuga totam, sapiente earum
        provident asperiores itaque et hic magnam sequi soluta aperiam eius
        porro voluptatum magni in? Vitae dolor labore exercitationem fugiat iste
        reprehenderit assumenda explicabo animi quas odit beatae doloremque
        dolorem non quis quasi esse officiis neque, facilis earum. Id minima
        veniam architecto, tempore non tenetur recusandae eum mollitia officia!
        Officia, voluptatum laboriosam, necessitatibus sed ipsum dolores libero
        natus saepe unde sint maxime! Quos adipisci esse temporibus animi! Animi
        quibusdam ex libero fugiat modi quas autem, quos debitis? Esse tenetur
        cupiditate non deserunt eveniet, beatae dolorum ullam suscipit impedit
        quo accusamus. Adipisci illum sed ex velit necessitatibus consequuntur,
        sapiente a quis at atque optio vitae rerum quas, odio quasi maiores
        unde. Voluptate enim ducimus expedita dolores commodi voluptas quas
        perferendis recusandae! Laboriosam error consectetur eum. Rerum est cum
        laborum consectetur repellat! Fugit consequuntur autem ab blanditiis
        corrupti, rem eveniet odit, nisi beatae, sequi odio accusantium dolore?
        Non maxime vitae officiis impedit, possimus dicta. Odio dolorum dolores
        consequatur molestias incidunt sed dolorem quo vitae saepe beatae nobis,
        recusandae magnam. Reprehenderit, culpa. Saepe, obcaecati. Ducimus id
        iusto vel officia vitae praesentium et, distinctio provident molestiae
        commodi facilis voluptate, voluptates, sint dicta inventore fugiat
        voluptatum eligendi eum aspernatur excepturi nulla. Eligendi, architecto
        explicabo. Iusto cum assumenda commodi? In sequi dolor dolorum ipsum
        culpa fugiat atque quisquam aliquid mollitia nesciunt doloremque
        obcaecati suscipit delectus ut provident harum, nihil quidem impedit
        alias repellat rem voluptatem nisi. Deserunt quisquam ratione veniam ex
        possimus quasi quaerat ipsa nobis qui, beatae numquam dolore id ipsam
        eaque blanditiis earum ullam amet aliquam! Earum voluptas exercitationem
        id tempora itaque quae error quibusdam non, sequi, quo mollitia! Quo
        cumque dolore quidem ipsum, accusamus harum doloremque perspiciatis
        perferendis unde nihil magnam, totam culpa quibusdam illum repellat et
        corporis quis. Natus dignissimos totam velit distinctio suscipit vitae
        minus cumque officiis, facere, ipsa alias molestias nihil recusandae
        placeat ex quibusdam. Dicta molestias saepe ipsum tempore assumenda ipsa
        laboriosam officia nulla! Error beatae accusamus commodi aliquid
        blanditiis aperiam debitis sunt, exercitationem facilis natus cupiditate
        quis veritatis saepe cumque vel eaque voluptatibus? Vitae at dolor
        ratione minus optio animi quibusdam. Tempora, eum iure facere cum eaque
        expedita illum temporibus, vel sunt nemo earum, quas minima tenetur
        ducimus ex. Officia nostrum consequatur quam ipsam unde expedita, fuga
        neque, cupiditate recusandae natus laborum. Et quasi, id, accusamus qui
        provident expedita ullam, debitis magni perferendis nulla asperiores.
        Repellat illum cum amet. Mollitia aspernatur dolor, nam amet odit
        quisquam earum qui nulla, possimus, fugiat aperiam quae repellendus
        omnis. Nulla sit excepturi optio, iste numquam incidunt voluptatum
        adipisci itaque amet, suscipit ipsam temporibus et, reprehenderit
        distinctio aliquid minima modi natus debitis? Fugiat quaerat id vel
        pariatur, laudantium neque possimus, tempora aliquam, nisi magni
        repellendus dolor mollitia animi ex ullam nihil minus dicta soluta
        asperiores cum ad. Maxime unde nobis quo delectus eos eligendi fugit a.
        Ipsum rem, repellendus nam dignissimos tempora saepe consequatur nobis
        voluptatibus illo quis eos praesentium, corrupti laboriosam eligendi
        ipsa neque error sed expedita fuga quas quaerat! Saepe distinctio
        provident numquam? Optio fugiat, vel omnis totam quo voluptas molestiae
        ex enim dolor eum, reiciendis assumenda quidem adipisci repellendus eos
        mollitia exercitationem deleniti velit excepturi architecto itaque
        minima laudantium minus. Deleniti, dolor? Corrupti, quam harum fugiat,
        illo eaque rerum aperiam cupiditate odit debitis autem laborum
        reprehenderit aut quos suscipit illum, voluptatibus quas eos quo quasi
        obcaecati porro vitae totam ullam. Optio sequi dolore hic consequatur
        earum architecto? Molestiae qui maxime molestias adipisci accusamus
        nulla explicabo non quae sunt ea natus fugiat cumque consequuntur, sint
        blanditiis distinctio dicta nihil fugit quod! Officiis, quae?\
      </p>
    </>
  );
}

export default Home1;
