import React from "react";

function Board() {
  return (
    <div class="container">
      <div class="at-grid" data-column="4">
        <div class="at-column">
          <div class="at-user">
            <div class="at-user__avatar">
              <img src={require("../Assets/images/andrei.png")} />
            </div>
            <div class="at-user__name">Diaconu Andrei</div>
            <div class="at-user__title">Președinte</div>
          </div>
        </div>
        <div class="at-column">
          <div class="at-user">
            <div class="at-user__avatar">
              {" "}
              <img src={require("../Assets/images/alina.png")} />
            </div>
            <div class="at-user__name">Epure Alina</div>
            <div class="at-user__title">Vicepreședinte</div>
          </div>
        </div>
        <div class="at-column">
          <div class="at-user">
            <div class="at-user__avatar">
              {" "}
              <img src={require("../Assets/images/tibi.png")} />
            </div>
            <div class="at-user__name">Șutu Tiberiu</div>
            <div class="at-user__title">Vicepreședinte Intern</div>
          </div>
        </div>
        <div class="at-column">
          <div class="at-user">
            <div class="at-user__avatar">
              {" "}
              <img src={require("../Assets/images/DSC_0669.jpg")} />
            </div>
            <div class="at-user__name">Hanga Mihail</div>
            <div class="at-user__title">Secretar General</div>
          </div>
        </div>
        <div class="at-column">
          <div class="at-user">
            <div class="at-user__avatar">
              {" "}
              <img src={require("../Assets/images/raducu.png")} />
            </div>
            <div class="at-user__name">Aciobăniței Radu</div>
            <div class="at-user__title">Coordonator HR</div>
          </div>
        </div>
        <div class="at-column">
          <div class="at-user">
            <div class="at-user__avatar">
              {" "}
              <img src={require("../Assets/images/robert.png")} />
            </div>
            <div class="at-user__name">Durlan Robert</div>
            <div class="at-user__title">Coordonator PR</div>
          </div>
        </div>
        <div class="at-column">
          <div class="at-user">
            <div class="at-user__avatar">
              {" "}
              <img src={require("../Assets/images/Denisa.png")} />
            </div>
            <div class="at-user__name">Stanciu Denisa</div>
            <div class="at-user__title">Coordonator Fundraising</div>
          </div>
        </div>
        <div class="at-column">
          <div class="at-user">
            <div class="at-user__avatar">
              {" "}
              <img src={require("../Assets/images/radu.png")} />
            </div>
            <div class="at-user__name">Andrei Radu</div>
            <div class="at-user__title">Coordonator Vocațional</div>
          </div>
        </div>
        <div class="at-column">
          <div class="at-user">
            <div class="at-user__avatar">
              {" "}
              <img src={require("../Assets/images/carmen.png")} />
            </div>
            <div class="at-user__name">Șelaru Carmen</div>
            <div class="at-user__title">Coordonator Tehnic</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Board;
