import { initializeApp } from "firebase/app";
import {
  orderByChild,
  equalTo,
  getDatabase,
  ref,
  push,
  get,
  update,
  remove,
  onValue,
  query,
} from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

class Fire {
  constructor() {
    const firebaseConfig = {
      apiKey: "AIzaSyBYxVbWorACCE67JW_DlsralKDDLPZF-_k",
      authDomain: "osfiir-44cc6.firebaseapp.com",
      databaseURL:
        "https://osfiir-44cc6-default-rtdb.europe-west1.firebasedatabase.app",
      projectId: "osfiir-44cc6",
      storageBucket: "osfiir-44cc6.appspot.com",
      messagingSenderId: "43718681260",
      appId: "1:43718681260:web:cda85197f57d48d2273509",
      measurementId: "G-SM8FPV84ZH",
    };

    const app = initializeApp(firebaseConfig);
    this.database = getDatabase(app);
    this.storage = getStorage(app);
  }

  async getData(path) {
    try {
      const dataSnapshot = await get(ref(this.database, path));
      return dataSnapshot.val();
    } catch (error) {
      console.error("Error getting data: ", error);
      throw error;
    }
  }

  async updateData(path, newData) {
    try {
      await update(ref(this.database, path), newData);
      console.log("Data successfully updated!");
    } catch (error) {
      console.error("Error updating data: ", error);
      throw error;
    }
  }

  async deleteData(path) {
    try {
      await remove(ref(this.database, path));
      console.log("Data successfully deleted!");
    } catch (error) {
      console.error("Error deleting data: ", error);
      throw error;
    }
  }

  listenForChanges(path, callback) {
    try {
      const dataRef = ref(this.database, path);
      onValue(dataRef, (snapshot) => {
        const data = snapshot.val();
        callback(data);
      });
    } catch (error) {
      console.error("Error listening for changes: ", error);
      throw error;
    }
  }
  async getDocumentByMatricol(path, matricolValue) {
    try {
      const allDocumentsQuery = query(
        ref(this.database, path),
        orderByChild("nr_matricol")
      );

      const querySnapshot = await get(allDocumentsQuery);

      if (querySnapshot.exists()) {
        const documents = querySnapshot.val();

        // Filter documents based on the "nr_matricol" field
        const matchingDocuments = Object.values(documents).filter(
          (document) => document.nr_matricol === matricolValue
        );

        if (matchingDocuments.length > 0) {
          // Return the first matching document
          return matchingDocuments[0];
        } else {
          console.log("Document not found");
          return null;
        }
      } else {
        console.log("No documents found in the collection");
        return null;
      }
    } catch (error) {
      console.error("Error getting document by matricol: ", error);
      throw error;
    }
  } async insertData(path, newData) {
    try {
      const newRef = push(ref(this.database, path), newData);
      console.log("Data successfully inserted!");
      return newRef;
    } catch (error) {
      console.error("Error inserting data: ", error);
      throw error;
    }
  }


  async uploadImage(file) {
    try {
      const storageReference = storageRef(this.storage, `images/${file.name}`);
      await uploadBytes(storageReference, file);
      const url = await getDownloadURL(storageReference);
      return url;
    } catch (error) {
      console.error("Error uploading image: ", error);
      throw error;
    }
  }

  async insertDataWithImages(path, newData, imageFiles) {
    try {
      const imageUrls = await Promise.all(
        imageFiles.map((file) => this.uploadImage(file))
      );

      newData.imageUrls = imageUrls;

      const newRef = push(ref(this.database, path), newData);
      console.log("Data with images successfully inserted!");
      return newRef;
    } catch (error) {
      console.error("Error inserting data with images: ", error);
      throw error;
    }
  }
}

export default Fire;
