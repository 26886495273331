import React, { useEffect, useState } from "react";
import "./party.scss";
import axios from "axios";
import Fire from "../utils/Fire";
import { toast } from "react-toastify";
import { FileUploader } from "react-drag-drop-files";
import { Timestamp } from "firebase/firestore";

const fire = new Fire();
const FILE_TYPE = ["JPG", "PNG", "GIF"];

function Party() {
  const [nume, setNume] = useState("");
  const [facultate, setFacultate] = useState("");
  const [materie, setMaterie] = useState("");

  const [nume_give, setNume_give] = useState("");
  const [poza1, setPoza1] = useState(null);
  const [poza2, setPoza2] = useState(null);
  const [poza3, setPoza3] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loading_give, setLoadingGive] = useState(false);

  const join = async () => {
    console.log(nume, facultate, materie);
    if (nume == "" || facultate == "" || materie == "") {
      toast("Completeaza toate datele!");
      console.log("asd");
      return;
    }

    setLoading(true);

    await fire
      .insertData("/party", { nume, facultate, materie })
      .then((res) => {
        console.log(res);
        toast("Te-ai inregistrat cu succes! See you there!!");
        setLoading(false);
      });
  };

  const handlefile1 = (file) => {
    setPoza1(file);
  };
  const handlefile2 = (file) => {
    setPoza2(file);
  };
  const handlefile3 = (file) => {
    setPoza3(file);
  };

  //   useEffect(() => {
  //     fire.getData("/giveaway").then((res) => {
  //       setData(Object.values(res));
  //       console.log(Object.values(res));
  //     });

  //     fire.listenForChanges("/giveaway", (res) => {
  //       setData(Object.values(res));
  //     });
  //   }, []);

  const inscr = async () => {
    if (
      nume_give == "" ||
      poza1 == null ||
      poza2 == null ||
      poza3 == null ||
      facultate == ""
    ) {
      toast("Completeaza toate datele!");
      console.log("asd");
      return;
    }

    setLoadingGive(true);

    await fire
      .insertDataWithImages(
        "/giveaway",
        {
          nume_give,
          createdAt: Timestamp.now(),
          facultate,
        },
        [poza1, poza2, poza3]
      )
      .then((res) => {
        console.log(res);
        toast("Te-ai inscris cu succes in giveaway!");
        setLoadingGive(false);
      });
  };
  return (
    <div className="party">
      <div className="imgs">
        <img src={require("../Assets/images/1.png")} alt="" />
        <img src={require("../Assets/images/2.png")} alt="" />
        <img src={require("../Assets/images/3.png")} alt="" />
      </div>
      {/* <div className="h1">Formular de inscriere</div>
      <div className="row">
        <h3>Nume si prenume</h3>
        <input
          type="text"
          onChange={(e) => setNume(e.target.value.toLowerCase())}
        />
      </div>
      <div className="row">
        <h3>Facultate</h3>
        <input
          type="text"
          onChange={(e) => setFacultate(e.target.value.toLowerCase())}
        />
      </div>
      <div className="row">
        <h3>Cea mai stresantă materie</h3>
        <input
          type="text"
          onChange={(e) => setMaterie(e.target.value.toLowerCase())}
        />
      </div>
      {!loading ? (
        <button onClick={join}>Join us</button>
      ) : (
        <>
          <br />
          <br />
          <div class="loader"></div>
        </>
      )} */}

      <div className="row">
        <h3 className="give">GIVEAWAY</h3>
        <h5>REGULI</h5>
        <p>
          Incarcă dovezile cu distribuirea la story a postarilor in care este
          anuntata petrecerea si vino la petrecere alaturi de un/o prieten(a)
          pentru a-ti revedica 2 shot-uri din partea noastra.
        </p>
        <p>
          Doar persoanele care se inscriu si indeplinesc toate criteriile pot
          castiga shot-urile.
        </p>
        <input
          type="text"
          placeholder="Nume si prenume"
          onChange={(e) => setNume_give(e.target.value.toLowerCase())}
        />

        <input
          type="text"
          placeholder="Facultate"
          onChange={(e) => setFacultate(e.target.value.toLowerCase())}
        />

        <FileUploader
          handleChange={handlefile1}
          name="file"
          types={FILE_TYPE}
        />
        <FileUploader
          handleChange={handlefile2}
          name="file"
          types={FILE_TYPE}
        />
        <FileUploader
          handleChange={handlefile3}
          name="file"
          types={FILE_TYPE}
        />
        {!loading_give ? (
          <button onClick={inscr}>Inscriere</button>
        ) : (
          <>
            <br />
            <br />
            <div class="loader"></div>
          </>
        )}
      </div>
    </div>
  );
}

export default Party;
