import React, { useEffect, useRef, useState } from "react";
import Fire from "../utils/Fire";
import "../Assets/scss/voluntari.scss";
import osfiir1 from "../Assets/docs/voluntariat/osfiir/Apreciere.docx";
import osfiir2 from "../Assets/docs/voluntariat/osfiir/Contract de voluntariat.docx";
import osfiir3 from "../Assets/docs/voluntariat/osfiir/Fisa de voluntariat (1).docx";
import osfiir4 from "../Assets/docs/voluntariat/osfiir/Raport de activitate (1).docx";

import alta from "../Assets/docs/voluntariat/alta/Contract de voluntariat cu alta organizatie.docx";
import alta2 from "../Assets/docs/voluntariat/alta/Fisa de voluntariat.docx";
import alta3 from "../Assets/docs/voluntariat/alta/Raport de activitate.docx";
import Footer from "../Components/Footer";

const fire = new Fire();

function Voluntari() {
  const [nr, setNr] = useState("");
  const [voluntar, setVoluntar] = useState({});

  const search = async () => {
    await fire.getDocumentByMatricol("/voluntari", nr).then((res) => {
      if (!res) {
        alert("Nr matricol gresit!");
        return;
      }
      setVoluntar(res);
      setNr("");
      executeScroll();
    });
  };

  const myRef = useRef(null);
  const executeScroll = () => {
    // if(myRef.current?.)
    myRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const check = async (event) => {
    if (event.key === "Enter") {
      await search();
    }
  };
  return (
    <>
      <section className="search">
        <img
          src={require("../Assets/images/vale.svg").default}
          className="vale"
          alt=""
        />
        <div className="find">
          <h1>
            Caută-ți numărul matricol și vezi rezumatul activităților tale
          </h1>
          <div className="row">
            <input
              type="text"
              onKeyDown={check}
              value={nr}
              onChange={(e) => setNr(e.target.value.toUpperCase())}
              placeholder="Nr. matricol/Vol. Matricol/an     ex: 243/11C/2023"
            />
            <div className="button" onClick={search}>
              <span>Caută</span>
            </div>
          </div>
        </div>

        <div className="acte">
          <h2>Acte necesare</h2>
          <div className="act">
            <h4>Dacă ai făcut voluntariat la OSFIIR</h4>
            <a href={osfiir1} download>
              Apreciere.docx
            </a>
            <a href={osfiir2} download>
              Contract de voluntariat.docx
            </a>
            <a href={osfiir3} download>
              Fisa de voluntariat.docx
            </a>
            <a href={osfiir4} download>
              Raport de activitate.docx
            </a>
          </div>
          <br />
          <div className="act">
            <h4>Dacă nu ai făcut voluntariat la OSFIIR</h4>
            <a href={alta} download>
              Contract de voluntariat cu alta organizatie.docx
            </a>
            <a href={alta2} download>
              Fisa de voluntariat.docx
            </a>
            <a href={alta3} download>
              Raport de activitate.docx
            </a>
          </div>
          <br />
          <h2>
            În ambele cazuri, studentul trebuie să aibă un CV și un dosar cu
            numele lui (recomandăm să fie de carton)!
          </h2>
          {/* <a
            href="https://drive.google.com/drive/folders/1J13c6OmdMkAGoSHTReHlUVO2h3bb1Ku_?usp=sharing"
            target="_blank"
          >
            Exemplu de dosar complet
          </a> */}
        </div>
        <br />
        <br />
        <div className="rezumat" ref={myRef}>
          {Object.keys(voluntar).length != 0 && (
            <>
              <h1>
                Rezumatul activităților tale, <b> {voluntar.nume}</b>
              </h1>
              <ul>
                <li>Nume: {voluntar.nume}</li>
                <li> Grupa: {voluntar.grupa}</li>
                {voluntar.isOSFIIR && (
                  <>
                    <li>{voluntar.nr_ore} ore de voluntariat la OSFIIR</li>
                    <li>
                      {voluntar.activitati.length == 0
                        ? "Nu ai participat la nici o activitate de la OSFIIR"
                        : "Ai participat la " +
                          (voluntar.activitati.length == 1
                            ? "o singură activitate de la OSFIIR"
                            : voluntar.activitati.length > 19
                            ? voluntar.activitati.length +
                              " de activități de la OSFIIR"
                            : voluntar.activitati.length +
                              " activități de la OSFIIR")}
                    </li>
                  </>
                )}
              </ul>
              {voluntar.isOSFIIR ? (
                <div className="cave">
                  <table>
                    <thead>
                      <tr>
                        {voluntar.activitati.map((act) => {
                          return <th>{act.name}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {voluntar.activitati.map((act) => {
                          return <td>{act.ore} ore</td>;
                        })}
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                <>
                  <br />
                  <br />
                  <br />
                  <br />
                  <h1 style={{ textAlign: "center" }}>
                    Datorita faptului că nu ești membru OSFIIR nu ți se pot
                    calcula orele de voluntariat oferite de noi.
                  </h1>
                </>
              )}
            </>
          )}
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </section>
      <Footer />
    </>
  );
}

export default Voluntari;
