import React from "react";
import useWindowSize from "../utils/useWindowSize";
import flayer from "../Assets/docs/Flyer.pdf";
// import flayer1 from "/public/Flyer.pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Ptc() {
  const [width, height] = useWindowSize();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100lvw",
        overflow: "hidden",
      }}
    >
      <Document file={flayer} style={{ overflow: "hidden" }}>
        <Page
          // width={width >= 1200 ? (90 / 100) * width : width}
          width={width}
          pageNumber={1}
          height={"auto"}
        />
        <Page
          width={width}
          // width={width >= 1200 ? (90 / 100) * width : width}
          pageNumber={2}
          height={"auto"}
        />
      </Document>
    </div>
  );
}

export default Ptc;
