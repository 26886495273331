import React, { useEffect } from "react";
import "../Assets/scss/home2.scss";
import CountUp from "react-countup";
import Board from "../Components/Board";
import "../Assets/scss/board.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

function Home2() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <section className="home" data-aos="fade-in">
        <div className="texts">
          <h1 data-aos="fade-up">
            Organizația Studenților din Facultatea de Inginerie Industrială și
            Robotică
          </h1>
          <div className="sub" data-aos="fade-down">
            <h3>FII PE FIIR!</h3>
            <div className="line"></div>
            <div className="button">
              <Link to='/voluntariat'>
                Voluntariat
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="numbers">
        <div className="l">
          <h1 data-aos="fade-right">
            CHECK <br /> <span  >OUT</span>
          </h1>
          <div className="button" data-aos="fade-left">
            <a target="_blank"  href="#">
              See More
            </a>
          </div>
        </div>
        <div className="r">
          <div className="number"data-aos="fade-down">
            <h2>Ani:</h2>
            <CountUp
              end={17}
              redraw={true}
              enableScrollSpy
              scrollSpyDelay={600}
            />
          </div>
          <div className="number" data-aos="fade-down" data-aos-delay="300">
            <h2>Membrii:</h2>
            <CountUp
              end={33}
              redraw={true}
              enableScrollSpy
              scrollSpyDelay={600}
            />
          </div>
          <div className="number" data-aos="fade-down" data-aos-delay="500">
            <h2>Voluntari:</h2>
            <span>
              <CountUp
                end={200}
                redraw={true}
                enableScrollSpy
                scrollSpyDelay={600}
              />
              +
            </span>
          </div>
        </div>
      </section>
      <section className="about">
        <img src={require("../Assets/images/bout.svg").default} data-aos="fade-up" alt="" />
        <h1  data-aos="fade-right" data-aos-delay="300">ABOUT US</h1>
        <p className="p" data-aos="fade-right">
          <b>OSFIIR</b> este o organizație apolitică si nonguvernamentală ce are
          ca scop promovarea inițiativelor culturale si civice ale studenților
          și de asemenea să păzească drepturile lor. Din 2006, facem parte de
          asemenea din Uniunea Nationala a Studenților din România și suntem
          membri activi ai acestei Federații.
        </p>
      </section>
      <section className="board">
        <h1>BOARD</h1>
        <Board />
      </section>
      <section className="projects">
        <h1 data-aos="fade-up">Projects</h1>
        <div className="simples">
          <div className="simple"  data-aos="fade-right" >
            <img src={require("../Assets/images/bal.png")} alt="" />
            <p className="p">
              <b>OSFIIR</b> organizează anual Balul Bobocilor, dedică noilor
              studenți ai <b>FIIR</b>.
            </p>
          </div>
          <div className="simple" data-aos="fade-left" >
            <img src={require("../Assets/images/board.png")} alt="" />
            <p className="p">
              Periodic organizăm evenimente care au ca scop destinderea
              studenților, pentru că știm că între cursurile, laboratoarele și
              seminarele de la facultate, este nevoie și de un moment de{" "}
              <b>"relaxo"</b>.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home2;
