import React from "react";

function UnSvgRandom() {
  return (
    <svg width="1765" height="409" viewBox="0 0 1765 409" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="733" y="9" width="52" height="371" fill="#0193D9"/>
    <rect x="1079" y="9" width="52" height="319" fill="#0193D9"/>
    <rect x="1299" y="61" width="52" height="319" fill="#0193D9" fill-opacity="0.3"/>
    <mask id="mask0_23_2314"   maskUnits="userSpaceOnUse" x="1520" y="45" width="162" height="170">
    <rect x="1520" y="45" width="162" height="10" fill="#FFCD5C"/>
    <rect x="1520" y="63" width="162" height="10" fill="#FFCD5C"/>
    <rect x="1520" y="81" width="162" height="10" fill="#FFCD5C"/>
    <rect x="1520" y="99" width="162" height="10" fill="#FFCD5C"/>
    <rect x="1520" y="117" width="162" height="10" fill="#FFCD5C"/>
    <rect x="1520" y="135" width="162" height="10" fill="#FFCD5C"/>
    <rect x="1520" y="153" width="162" height="10" fill="#FFCD5C"/>
    <rect x="1520" y="171" width="162" height="10" fill="#FFCD5C"/>
    <rect x="1520" y="187" width="162" height="10" fill="#FFCD5C"/>
    <rect x="1520" y="205" width="162" height="10" fill="#FFCD5C"/>
    </mask>
    <g mask="url(#mask0_23_2314)">
    <ellipse cx="1601" cy="130" rx="70.6765" ry="89" fill="#FFCD5C"/>
    </g>
    <rect x="1513" y="10" width="52" height="369" fill="#0193D9"/>
    <path d="M1607 234.035L1653.11 210L1744.5 378.5L1683 378.5L1607 234.035Z" fill="#0193D9"/>
    <path d="M1563.28 9.78573C1583.66 9.88892 1603.82 13.1037 1622.61 19.2466C1641.4 25.3895 1658.46 34.3402 1672.81 45.5876C1687.16 56.8351 1698.52 70.1589 1706.24 84.7986C1713.96 99.4382 1717.9 115.107 1717.82 130.91C1717.74 146.713 1713.65 162.341 1705.78 176.902C1697.9 191.462 1686.41 204.67 1671.95 215.772C1657.48 226.873 1640.34 235.651 1621.48 241.603C1602.63 247.555 1582.44 250.566 1562.07 250.463L1562.27 210.484C1575.87 210.553 1589.36 208.542 1601.95 204.567C1614.54 200.593 1625.99 194.731 1635.64 187.318C1645.3 179.905 1652.98 171.084 1658.23 161.361C1663.49 151.638 1666.22 141.202 1666.28 130.649C1666.33 120.096 1663.7 109.633 1658.55 99.8567C1653.39 90.0806 1645.8 81.1832 1636.22 73.6724C1626.64 66.1616 1615.25 60.1845 1602.7 56.0824C1590.15 51.9803 1576.69 49.8335 1563.08 49.7646L1563.28 9.78573Z" fill="#0E6189"/>
    <rect x="735" y="61" width="52" height="235" transform="rotate(-90 735 61)" fill="#0193D9"/>
    <rect x="785" y="180" width="56" height="159" transform="rotate(-90 785 180)" fill="#FFCD5C" fill-opacity="0.79"/>
    <circle cx="806.5" cy="142.5" r="2.5" fill="black"/>
    <circle cx="817.5" cy="142.5" r="2.5" fill="black"/>
    <circle cx="828.5" cy="142.5" r="2.5" fill="black"/>
    <circle cx="839.5" cy="142.5" r="2.5" fill="black"/>
    <circle cx="850.5" cy="142.5" r="2.5" fill="black"/>
    <circle cx="861.5" cy="142.5" r="2.5" fill="black"/>
    <circle cx="872.5" cy="142.5" r="2.5" fill="black"/>
    <circle cx="883.5" cy="142.5" r="2.5" fill="black"/>
    <circle cx="894.5" cy="142.5" r="2.5" fill="black"/>
    <circle cx="905.5" cy="142.5" r="2.5" fill="black"/>
    <circle cx="916.5" cy="142.5" r="2.5" fill="black"/>
    <circle cx="927.5" cy="142.5" r="2.5" fill="black"/>
    <circle cx="938.5" cy="142.5" r="2.5" fill="black"/>
    <circle cx="949.5" cy="142.5" r="2.5" fill="black"/>
    <circle cx="806.5" cy="153.5" r="2.5" fill="black"/>
    <circle cx="817.5" cy="153.5" r="2.5" fill="black"/>
    <circle cx="828.5" cy="153.5" r="2.5" fill="black"/>
    <circle cx="839.5" cy="153.5" r="2.5" fill="black"/>
    <circle cx="850.5" cy="153.5" r="2.5" fill="black"/>
    <circle cx="861.5" cy="153.5" r="2.5" fill="black"/>
    <circle cx="872.5" cy="153.5" r="2.5" fill="black"/>
    <circle cx="883.5" cy="153.5" r="2.5" fill="black"/>
    <circle cx="894.5" cy="153.5" r="2.5" fill="black"/>
    <circle cx="905.5" cy="153.5" r="2.5" fill="black"/>
    <circle cx="916.5" cy="153.5" r="2.5" fill="black"/>
    <circle cx="927.5" cy="153.5" r="2.5" fill="black"/>
    <circle cx="938.5" cy="153.5" r="2.5" fill="black"/>
    <circle cx="949.5" cy="153.5" r="2.5" fill="black"/>
    <circle cx="806.5" cy="164.5" r="2.5" fill="black"/>
    <circle cx="817.5" cy="164.5" r="2.5" fill="black"/>
    <circle cx="828.5" cy="164.5" r="2.5" fill="black"/>
    <circle cx="839.5" cy="164.5" r="2.5" fill="black"/>
    <circle cx="850.5" cy="164.5" r="2.5" fill="black"/>
    <circle cx="861.5" cy="164.5" r="2.5" fill="black"/>
    <circle cx="872.5" cy="164.5" r="2.5" fill="black"/>
    <circle cx="883.5" cy="164.5" r="2.5" fill="black"/>
    <circle cx="894.5" cy="164.5" r="2.5" fill="black"/>
    <circle cx="905.5" cy="164.5" r="2.5" fill="black"/>
    <circle cx="916.5" cy="164.5" r="2.5" fill="black"/>
    <circle cx="927.5" cy="164.5" r="2.5" fill="black"/>
    <circle cx="938.5" cy="164.5" r="2.5" fill="black"/>
    <circle cx="949.5" cy="164.5" r="2.5" fill="black"/>
    <circle cx="806.5" cy="175.5" r="2.5" fill="black"/>
    <circle cx="817.5" cy="175.5" r="2.5" fill="black"/>
    <circle cx="828.5" cy="175.5" r="2.5" fill="black"/>
    <circle cx="839.5" cy="175.5" r="2.5" fill="black"/>
    <circle cx="850.5" cy="175.5" r="2.5" fill="black"/>
    <circle cx="861.5" cy="175.5" r="2.5" fill="black"/>
    <circle cx="872.5" cy="175.5" r="2.5" fill="black"/>
    <circle cx="883.5" cy="175.5" r="2.5" fill="black"/>
    <circle cx="894.5" cy="175.5" r="2.5" fill="black"/>
    <circle cx="905.5" cy="175.5" r="2.5" fill="black"/>
    <circle cx="916.5" cy="175.5" r="2.5" fill="black"/>
    <circle cx="927.5" cy="175.5" r="2.5" fill="black"/>
    <circle cx="938.5" cy="175.5" r="2.5" fill="black"/>
    <circle cx="949.5" cy="175.5" r="2.5" fill="black"/>
    <circle cx="806.5" cy="186.5" r="2.5" fill="black"/>
    <circle cx="817.5" cy="186.5" r="2.5" fill="black"/>
    <circle cx="828.5" cy="186.5" r="2.5" fill="black"/>
    <circle cx="839.5" cy="186.5" r="2.5" fill="black"/>
    <circle cx="850.5" cy="186.5" r="2.5" fill="black"/>
    <circle cx="861.5" cy="186.5" r="2.5" fill="black"/>
    <circle cx="872.5" cy="186.5" r="2.5" fill="black"/>
    <circle cx="883.5" cy="186.5" r="2.5" fill="black"/>
    <circle cx="894.5" cy="186.5" r="2.5" fill="black"/>
    <circle cx="905.5" cy="186.5" r="2.5" fill="black"/>
    <circle cx="916.5" cy="186.5" r="2.5" fill="black"/>
    <circle cx="927.5" cy="186.5" r="2.5" fill="black"/>
    <circle cx="938.5" cy="186.5" r="2.5" fill="black"/>
    <circle cx="949.5" cy="186.5" r="2.5" fill="black"/>
    <circle cx="720.5" cy="27.5" r="2.5" fill="#0E6189"/>
    <circle cx="731.5" cy="27.5" r="2.5" fill="#0E6189"/>
    <circle cx="742.5" cy="27.5" r="2.5" fill="#0E6189"/>
    <circle cx="753.5" cy="27.5" r="2.5" fill="#0E6189"/>
    <circle cx="764.5" cy="27.5" r="2.5" fill="#0E6189"/>
    <circle cx="775.5" cy="27.5" r="2.5" fill="#0E6189"/>
    <circle cx="786.5" cy="27.5" r="2.5" fill="#0E6189"/>
    <circle cx="797.5" cy="27.5" r="2.5" fill="#0E6189"/>
    <circle cx="808.5" cy="27.5" r="2.5" fill="#0E6189"/>
    <circle cx="819.5" cy="27.5" r="2.5" fill="#0E6189"/>
    <circle cx="830.5" cy="27.5" r="2.5" fill="#0E6189"/>
    <circle cx="841.5" cy="27.5" r="2.5" fill="#0E6189"/>
    <circle cx="852.5" cy="27.5" r="2.5" fill="#0E6189"/>
    <circle cx="863.5" cy="27.5" r="2.5" fill="#0E6189"/>
    <circle cx="874.5" cy="27.5" r="2.5" fill="#0E6189"/>
    <circle cx="885.5" cy="27.5" r="2.5" fill="#0E6189"/>
    <circle cx="896.5" cy="27.5" r="2.5" fill="#0E6189"/>
    <circle cx="907.5" cy="27.5" r="2.5" fill="#0E6189"/>
    <circle cx="918.5" cy="27.5" r="2.5" fill="#0E6189"/>
    <circle cx="929.5" cy="27.5" r="2.5" fill="#0E6189"/>
    <circle cx="940.5" cy="27.5" r="2.5" fill="#0E6189"/>
    <circle cx="720.5" cy="38.5" r="2.5" fill="#0E6189"/>
    <circle cx="731.5" cy="38.5" r="2.5" fill="#0E6189"/>
    <circle cx="742.5" cy="38.5" r="2.5" fill="#0E6189"/>
    <circle cx="753.5" cy="38.5" r="2.5" fill="#0E6189"/>
    <circle cx="764.5" cy="38.5" r="2.5" fill="#0E6189"/>
    <circle cx="775.5" cy="38.5" r="2.5" fill="#0E6189"/>
    <circle cx="786.5" cy="38.5" r="2.5" fill="#0E6189"/>
    <circle cx="797.5" cy="38.5" r="2.5" fill="#0E6189"/>
    <circle cx="808.5" cy="38.5" r="2.5" fill="#0E6189"/>
    <circle cx="819.5" cy="38.5" r="2.5" fill="#0E6189"/>
    <circle cx="830.5" cy="38.5" r="2.5" fill="#0E6189"/>
    <circle cx="841.5" cy="38.5" r="2.5" fill="#0E6189"/>
    <circle cx="852.5" cy="38.5" r="2.5" fill="#0E6189"/>
    <circle cx="863.5" cy="38.5" r="2.5" fill="#0E6189"/>
    <circle cx="874.5" cy="38.5" r="2.5" fill="#0E6189"/>
    <circle cx="885.5" cy="38.5" r="2.5" fill="#0E6189"/>
    <circle cx="896.5" cy="38.5" r="2.5" fill="#0E6189"/>
    <circle cx="907.5" cy="38.5" r="2.5" fill="#0E6189"/>
    <circle cx="918.5" cy="38.5" r="2.5" fill="#0E6189"/>
    <circle cx="929.5" cy="38.5" r="2.5" fill="#0E6189"/>
    <circle cx="940.5" cy="38.5" r="2.5" fill="#0E6189"/>
    <circle cx="720.5" cy="49.5" r="2.5" fill="#0E6189"/>
    <circle cx="731.5" cy="49.5" r="2.5" fill="#0E6189"/>
    <circle cx="742.5" cy="49.5" r="2.5" fill="#0E6189"/>
    <circle cx="753.5" cy="49.5" r="2.5" fill="#0E6189"/>
    <circle cx="764.5" cy="49.5" r="2.5" fill="#0E6189"/>
    <circle cx="775.5" cy="49.5" r="2.5" fill="#0E6189"/>
    <circle cx="786.5" cy="49.5" r="2.5" fill="#0E6189"/>
    <circle cx="797.5" cy="49.5" r="2.5" fill="#0E6189"/>
    <circle cx="808.5" cy="49.5" r="2.5" fill="#0E6189"/>
    <circle cx="819.5" cy="49.5" r="2.5" fill="#0E6189"/>
    <circle cx="830.5" cy="49.5" r="2.5" fill="#0E6189"/>
    <circle cx="841.5" cy="49.5" r="2.5" fill="#0E6189"/>
    <circle cx="852.5" cy="49.5" r="2.5" fill="#0E6189"/>
    <circle cx="863.5" cy="49.5" r="2.5" fill="#0E6189"/>
    <circle cx="874.5" cy="49.5" r="2.5" fill="#0E6189"/>
    <circle cx="885.5" cy="49.5" r="2.5" fill="#0E6189"/>
    <circle cx="896.5" cy="49.5" r="2.5" fill="#0E6189"/>
    <circle cx="907.5" cy="49.5" r="2.5" fill="#0E6189"/>
    <circle cx="918.5" cy="49.5" r="2.5" fill="#0E6189"/>
    <circle cx="929.5" cy="49.5" r="2.5" fill="#0E6189"/>
    <circle cx="940.5" cy="49.5" r="2.5" fill="#0E6189"/>
    <circle cx="720.5" cy="60.5" r="2.5" fill="#0E6189"/>
    <circle cx="731.5" cy="60.5" r="2.5" fill="#0E6189"/>
    <circle cx="742.5" cy="60.5" r="2.5" fill="#0E6189"/>
    <circle cx="753.5" cy="60.5" r="2.5" fill="#0E6189"/>
    <circle cx="764.5" cy="60.5" r="2.5" fill="#0E6189"/>
    <circle cx="775.5" cy="60.5" r="2.5" fill="#0E6189"/>
    <circle cx="786.5" cy="60.5" r="2.5" fill="#0E6189"/>
    <circle cx="797.5" cy="60.5" r="2.5" fill="#0E6189"/>
    <circle cx="808.5" cy="60.5" r="2.5" fill="#0E6189"/>
    <circle cx="819.5" cy="60.5" r="2.5" fill="#0E6189"/>
    <circle cx="830.5" cy="60.5" r="2.5" fill="#0E6189"/>
    <circle cx="841.5" cy="60.5" r="2.5" fill="#0E6189"/>
    <circle cx="852.5" cy="60.5" r="2.5" fill="#0E6189"/>
    <circle cx="863.5" cy="60.5" r="2.5" fill="#0E6189"/>
    <circle cx="874.5" cy="60.5" r="2.5" fill="#0E6189"/>
    <circle cx="885.5" cy="60.5" r="2.5" fill="#0E6189"/>
    <circle cx="896.5" cy="60.5" r="2.5" fill="#0E6189"/>
    <circle cx="907.5" cy="60.5" r="2.5" fill="#0E6189"/>
    <circle cx="918.5" cy="60.5" r="2.5" fill="#0E6189"/>
    <circle cx="929.5" cy="60.5" r="2.5" fill="#0E6189"/>
    <circle cx="940.5" cy="60.5" r="2.5" fill="#0E6189"/>
    <circle cx="720.5" cy="71.5" r="2.5" fill="#0E6189"/>
    <circle cx="731.5" cy="71.5" r="2.5" fill="#0E6189"/>
    <circle cx="742.5" cy="71.5" r="2.5" fill="#0E6189"/>
    <circle cx="753.5" cy="71.5" r="2.5" fill="#0E6189"/>
    <circle cx="764.5" cy="71.5" r="2.5" fill="#0E6189"/>
    <circle cx="775.5" cy="71.5" r="2.5" fill="#0E6189"/>
    <circle cx="786.5" cy="71.5" r="2.5" fill="#0E6189"/>
    <circle cx="797.5" cy="71.5" r="2.5" fill="#0E6189"/>
    <circle cx="808.5" cy="71.5" r="2.5" fill="#0E6189"/>
    <circle cx="819.5" cy="71.5" r="2.5" fill="#0E6189"/>
    <circle cx="830.5" cy="71.5" r="2.5" fill="#0E6189"/>
    <circle cx="841.5" cy="71.5" r="2.5" fill="#0E6189"/>
    <circle cx="852.5" cy="71.5" r="2.5" fill="#0E6189"/>
    <circle cx="863.5" cy="71.5" r="2.5" fill="#0E6189"/>
    <circle cx="874.5" cy="71.5" r="2.5" fill="#0E6189"/>
    <circle cx="885.5" cy="71.5" r="2.5" fill="#0E6189"/>
    <circle cx="896.5" cy="71.5" r="2.5" fill="#0E6189"/>
    <circle cx="907.5" cy="71.5" r="2.5" fill="#0E6189"/>
    <circle cx="918.5" cy="71.5" r="2.5" fill="#0E6189"/>
    <circle cx="929.5" cy="71.5" r="2.5" fill="#0E6189"/>
    <circle cx="940.5" cy="71.5" r="2.5" fill="#0E6189"/>
    <circle cx="720.5" cy="82.5" r="2.5" fill="#0E6189"/>
    <circle cx="731.5" cy="82.5" r="2.5" fill="#0E6189"/>
    <circle cx="742.5" cy="82.5" r="2.5" fill="#0E6189"/>
    <circle cx="753.5" cy="82.5" r="2.5" fill="#0E6189"/>
    <circle cx="764.5" cy="82.5" r="2.5" fill="#0E6189"/>
    <circle cx="775.5" cy="82.5" r="2.5" fill="#0E6189"/>
    <circle cx="786.5" cy="82.5" r="2.5" fill="#0E6189"/>
    <circle cx="797.5" cy="82.5" r="2.5" fill="#0E6189"/>
    <circle cx="808.5" cy="82.5" r="2.5" fill="#0E6189"/>
    <circle cx="819.5" cy="82.5" r="2.5" fill="#0E6189"/>
    <circle cx="830.5" cy="82.5" r="2.5" fill="#0E6189"/>
    <circle cx="841.5" cy="82.5" r="2.5" fill="#0E6189"/>
    <circle cx="852.5" cy="82.5" r="2.5" fill="#0E6189"/>
    <circle cx="863.5" cy="82.5" r="2.5" fill="#0E6189"/>
    <circle cx="874.5" cy="82.5" r="2.5" fill="#0E6189"/>
    <circle cx="885.5" cy="82.5" r="2.5" fill="#0E6189"/>
    <circle cx="896.5" cy="82.5" r="2.5" fill="#0E6189"/>
    <circle cx="907.5" cy="82.5" r="2.5" fill="#0E6189"/>
    <circle cx="918.5" cy="82.5" r="2.5" fill="#0E6189"/>
    <circle cx="929.5" cy="82.5" r="2.5" fill="#0E6189"/>
    <circle cx="940.5" cy="82.5" r="2.5" fill="#0E6189"/>
    <rect x="1022" y="61" width="52" height="166" transform="rotate(-90 1022 61)" fill="#0193D9"/>
    <path d="M1034.63 347.852L1044.55 355.311L1053.82 347.852L1065.97 355.311L1076.84 348.176L1084.19 354.825L1096.34 348.663L1108.01 355.798L1118.41 348.176L1128 354.338L1136.94 348.176L1147.86 354.825L1158.13 347.852L1170.71 354.338" stroke="#0E6189" stroke-width="2" stroke-linecap="round"/>
    <path d="M1032.65 353.811L1042.56 361.271L1051.83 353.811L1063.98 361.271L1074.85 354.136L1082.21 360.784L1094.36 354.622L1106.03 361.757L1116.42 354.136L1126.01 360.298L1134.95 354.136L1145.88 360.784L1156.14 353.811L1168.72 360.298" stroke="#0E6189" stroke-width="2" stroke-linecap="round"/>
    <path d="M1031.32 360.433L1041.24 367.892L1050.51 360.433L1062.66 367.892L1073.53 360.757L1080.88 367.406L1093.03 361.244L1104.7 368.379L1115.1 360.757L1124.69 366.919L1133.63 360.757L1144.55 367.406L1154.81 360.433L1167.4 366.919" stroke="#0E6189" stroke-width="2" stroke-linecap="round"/>
    <path d="M1030 367.054L1039.91 374.514L1049.18 367.054L1061.33 374.514L1072.21 367.379L1079.56 374.027L1091.71 367.865L1103.38 375L1113.77 367.379L1123.36 373.541L1132.3 367.379L1143.23 374.027L1153.49 367.054L1166.07 373.541" stroke="#0E6189" stroke-width="2" stroke-linecap="round"/>
    <path d="M1035.83 341.29L1045.74 348.75L1055.02 341.29L1067.17 348.75L1078.04 341.615L1085.39 348.263L1097.54 342.101L1109.21 349.236L1119.6 341.615L1129.2 347.777L1138.13 341.615L1149.06 348.263L1159.32 341.29L1171.9 347.777" stroke="#0E6189" stroke-width="2" stroke-linecap="round"/>
    <path d="M1035.1 334L1045.02 341.459L1054.29 334L1066.44 341.459L1077.31 334.324L1084.66 340.973L1096.81 334.811L1108.48 341.946L1118.87 334.324L1128.47 340.486L1137.41 334.324L1148.33 340.973L1158.59 334L1171.17 340.486" stroke="#0E6189" stroke-width="2" stroke-linecap="round"/>
    <rect x="1022" y="380" width="52" height="166" transform="rotate(-90 1022 380)" fill="#0193D9" fill-opacity="0.69"/>
    <rect x="1242" y="380" width="52" height="166" transform="rotate(-90 1242 380)" fill="#0193D9"/>
    <path d="M1242 61V44C1242 24.67 1257.67 9 1277 9L1408 9V26C1408 45.33 1392.33 61 1373 61L1242 61Z" fill="#0193D9"/>
    <path d="M1012.36 21L1025.95 31.2315L1038.67 21L1055.34 31.2315L1070.25 21.4448L1080.34 30.5643L1097 22.1121L1113.01 31.8988L1127.26 21.4448L1140.42 29.897L1152.68 21.4448L1167.67 30.5643L1181.74 21L1199 29.897" stroke="#FFCD5C" stroke-width="2" stroke-linecap="round"/>
    <path d="M1009.63 29.1741L1023.23 39.4057L1035.95 29.1741L1052.61 39.4057L1067.52 29.619L1077.61 38.7384L1094.28 30.2862L1110.28 40.0729L1124.54 29.619L1137.69 38.0711L1149.96 29.619L1164.94 38.7384L1179.02 29.1741L1196.28 38.0711" stroke="#FFCD5C" stroke-width="2" stroke-linecap="round"/>
    <path d="M1007.82 38.2565L1021.41 48.488L1034.13 38.2565L1050.8 48.488L1065.71 38.7013L1075.79 47.8207L1092.46 39.3686L1108.47 49.1553L1122.72 38.7013L1135.88 47.1535L1148.14 38.7013L1163.12 47.8207L1177.2 38.2565L1194.46 47.1535" stroke="#FFCD5C" stroke-width="2" stroke-linecap="round"/>
    <path d="M1006 47.3388L1019.6 57.5704L1032.31 47.3388L1048.98 57.5704L1063.89 47.7837L1073.98 56.9031L1090.64 48.4509L1106.65 58.2376L1120.9 47.7837L1134.06 56.2358L1146.32 47.7837L1161.31 56.9031L1175.39 47.3388L1192.64 56.2358" stroke="#FFCD5C" stroke-width="2" stroke-linecap="round"/>
    <path d="M1014 12L1027.6 22.2315L1040.31 12L1056.98 22.2315L1071.89 12.4448L1081.98 21.5643L1098.64 13.1121L1114.65 22.8988L1128.9 12.4448L1142.06 20.897L1154.32 12.4448L1169.31 21.5643L1183.39 12L1200.64 20.897" stroke="#FFCD5C" stroke-width="2" stroke-linecap="round"/>
    <path d="M1013 2L1026.6 12.2315L1039.31 2L1055.98 12.2315L1070.89 2.44485L1080.98 11.5643L1097.64 3.11212L1113.65 12.8988L1127.9 2.44485L1141.06 10.897L1153.32 2.44485L1168.31 11.5643L1182.39 2L1199.64 10.897" stroke="#FFCD5C" stroke-width="2" stroke-linecap="round"/>
    <path d="M1661.64 209.197L1658.52 226.741L1673.55 234.805L1671.81 255.336L1687.43 265.721L1683.73 279.382L1698.93 292.037L1697.3 311.74L1713.23 321.204L1711.52 337.571L1724.73 345.939L1723.24 364.368L1738.28 374.035L1738.02 394.579" stroke="#FFCD5C" stroke-width="2" stroke-linecap="round"/>
    <path d="M1653 209.992L1649.87 227.536L1664.9 235.599L1663.17 256.131L1678.79 266.516L1675.08 280.176L1690.29 292.831L1688.65 312.535L1704.58 321.998L1702.87 338.365L1716.08 346.734L1714.59 365.162L1729.64 374.83L1729.38 395.374" stroke="#FFCD5C" stroke-width="2" stroke-linecap="round"/>
    <path d="M1643.94 212.053L1640.81 229.597L1655.85 237.66L1654.11 258.192L1669.73 268.577L1666.02 282.238L1681.23 294.892L1679.6 314.596L1695.52 324.06L1693.81 340.427L1707.02 348.795L1705.54 367.223L1720.58 376.891L1720.32 397.435" stroke="#FFCD5C" stroke-width="2" stroke-linecap="round"/>
    <path d="M1634.88 214.114L1631.76 231.658L1646.79 239.722L1645.05 260.253L1660.67 270.638L1656.97 284.299L1672.17 296.954L1670.54 316.657L1686.47 326.121L1684.75 342.488L1697.97 350.857L1696.48 369.285L1711.52 378.952L1711.26 399.496" stroke="#FFCD5C" stroke-width="2" stroke-linecap="round"/>
    <path d="M1670.55 207.001L1667.42 224.545L1682.45 232.609L1680.71 253.14L1696.34 263.525L1692.63 277.186L1707.83 289.841L1706.2 309.544L1722.13 319.008L1720.42 335.375L1733.63 343.743L1732.14 362.172L1747.18 371.839L1746.92 392.383" stroke="#FFCD5C" stroke-width="2" stroke-linecap="round"/>
    <path d="M1679.16 201.812L1676.04 219.356L1691.07 227.419L1689.33 247.951L1704.95 258.336L1701.25 271.997L1716.45 284.652L1714.82 304.355L1730.75 313.819L1729.03 330.186L1742.25 338.554L1740.76 356.983L1755.8 366.65L1755.54 387.194" stroke="#FFCD5C" stroke-width="2" stroke-linecap="round"/>
    <circle cx="1319.5" cy="71.5" r="2.5" fill="black"/>
    <circle cx="1330.5" cy="71.5" r="2.5" fill="black"/>
    <circle cx="1341.5" cy="71.5" r="2.5" fill="black"/>
    <circle cx="1352.5" cy="71.5" r="2.5" fill="black"/>
    <circle cx="1363.5" cy="71.5" r="2.5" fill="black"/>
    <circle cx="1319.5" cy="82.5" r="2.5" fill="black"/>
    <circle cx="1330.5" cy="82.5" r="2.5" fill="black"/>
    <circle cx="1341.5" cy="82.5" r="2.5" fill="black"/>
    <circle cx="1352.5" cy="82.5" r="2.5" fill="black"/>
    <circle cx="1363.5" cy="82.5" r="2.5" fill="black"/>
    <circle cx="1319.5" cy="93.5" r="2.5" fill="black"/>
    <circle cx="1330.5" cy="93.5" r="2.5" fill="black"/>
    <circle cx="1341.5" cy="93.5" r="2.5" fill="black"/>
    <circle cx="1352.5" cy="93.5" r="2.5" fill="black"/>
    <circle cx="1363.5" cy="93.5" r="2.5" fill="black"/>
    <circle cx="1319.5" cy="104.5" r="2.5" fill="black"/>
    <circle cx="1330.5" cy="104.5" r="2.5" fill="black"/>
    <circle cx="1341.5" cy="104.5" r="2.5" fill="black"/>
    <circle cx="1352.5" cy="104.5" r="2.5" fill="black"/>
    <circle cx="1363.5" cy="104.5" r="2.5" fill="black"/>
    <circle cx="1319.5" cy="115.5" r="2.5" fill="black"/>
    <circle cx="1330.5" cy="115.5" r="2.5" fill="black"/>
    <circle cx="1341.5" cy="115.5" r="2.5" fill="black"/>
    <circle cx="1352.5" cy="115.5" r="2.5" fill="black"/>
    <circle cx="1363.5" cy="115.5" r="2.5" fill="black"/>
    <circle cx="1319.5" cy="126.5" r="2.5" fill="black"/>
    <circle cx="1330.5" cy="126.5" r="2.5" fill="black"/>
    <circle cx="1341.5" cy="126.5" r="2.5" fill="black"/>
    <circle cx="1352.5" cy="126.5" r="2.5" fill="black"/>
    <circle cx="1363.5" cy="126.5" r="2.5" fill="black"/>
    <circle cx="1319.5" cy="137.5" r="2.5" fill="black"/>
    <circle cx="1330.5" cy="137.5" r="2.5" fill="black"/>
    <circle cx="1341.5" cy="137.5" r="2.5" fill="black"/>
    <circle cx="1352.5" cy="137.5" r="2.5" fill="black"/>
    <circle cx="1363.5" cy="137.5" r="2.5" fill="black"/>
    <circle cx="1319.5" cy="148.5" r="2.5" fill="black"/>
    <circle cx="1330.5" cy="148.5" r="2.5" fill="black"/>
    <circle cx="1341.5" cy="148.5" r="2.5" fill="black"/>
    <circle cx="1352.5" cy="148.5" r="2.5" fill="black"/>
    <circle cx="1363.5" cy="148.5" r="2.5" fill="black"/>
    <circle cx="1319.5" cy="159.5" r="2.5" fill="black"/>
    <circle cx="1330.5" cy="159.5" r="2.5" fill="black"/>
    <circle cx="1341.5" cy="159.5" r="2.5" fill="black"/>
    <circle cx="1352.5" cy="159.5" r="2.5" fill="black"/>
    <circle cx="1363.5" cy="159.5" r="2.5" fill="black"/>
    <circle cx="1319.5" cy="170.5" r="2.5" fill="black"/>
    <circle cx="1330.5" cy="170.5" r="2.5" fill="black"/>
    <circle cx="1341.5" cy="170.5" r="2.5" fill="black"/>
    <circle cx="1352.5" cy="170.5" r="2.5" fill="black"/>
    <circle cx="1363.5" cy="170.5" r="2.5" fill="black"/>
    <circle cx="1319.5" cy="181.5" r="2.5" fill="black"/>
    <circle cx="1330.5" cy="181.5" r="2.5" fill="black"/>
    <circle cx="1341.5" cy="181.5" r="2.5" fill="black"/>
    <circle cx="1352.5" cy="181.5" r="2.5" fill="black"/>
    <circle cx="1363.5" cy="181.5" r="2.5" fill="black"/>
    <circle cx="1319.5" cy="192.5" r="2.5" fill="black"/>
    <circle cx="1330.5" cy="192.5" r="2.5" fill="black"/>
    <circle cx="1341.5" cy="192.5" r="2.5" fill="black"/>
    <circle cx="1352.5" cy="192.5" r="2.5" fill="black"/>
    <circle cx="1363.5" cy="192.5" r="2.5" fill="black"/>
    <circle cx="1319.5" cy="203.5" r="2.5" fill="black"/>
    <circle cx="1330.5" cy="203.5" r="2.5" fill="black"/>
    <circle cx="1341.5" cy="203.5" r="2.5" fill="black"/>
    <circle cx="1352.5" cy="203.5" r="2.5" fill="black"/>
    <circle cx="1363.5" cy="203.5" r="2.5" fill="black"/>
    <circle cx="1319.5" cy="214.5" r="2.5" fill="black"/>
    <circle cx="1330.5" cy="214.5" r="2.5" fill="black"/>
    <circle cx="1341.5" cy="214.5" r="2.5" fill="black"/>
    <circle cx="1352.5" cy="214.5" r="2.5" fill="black"/>
    <circle cx="1363.5" cy="214.5" r="2.5" fill="black"/>
    <circle cx="1319.5" cy="225.5" r="2.5" fill="black"/>
    <circle cx="1330.5" cy="225.5" r="2.5" fill="black"/>
    <circle cx="1341.5" cy="225.5" r="2.5" fill="black"/>
    <circle cx="1352.5" cy="225.5" r="2.5" fill="black"/>
    <circle cx="1363.5" cy="225.5" r="2.5" fill="black"/>
    <circle cx="1319.5" cy="236.5" r="2.5" fill="black"/>
    <circle cx="1330.5" cy="236.5" r="2.5" fill="black"/>
    <circle cx="1341.5" cy="236.5" r="2.5" fill="black"/>
    <circle cx="1352.5" cy="236.5" r="2.5" fill="black"/>
    <circle cx="1363.5" cy="236.5" r="2.5" fill="black"/>
    <circle cx="1319.5" cy="247.5" r="2.5" fill="black"/>
    <circle cx="1330.5" cy="247.5" r="2.5" fill="black"/>
    <circle cx="1341.5" cy="247.5" r="2.5" fill="black"/>
    <circle cx="1352.5" cy="247.5" r="2.5" fill="black"/>
    <circle cx="1363.5" cy="247.5" r="2.5" fill="black"/>
    <circle cx="1319.5" cy="258.5" r="2.5" fill="black"/>
    <circle cx="1330.5" cy="258.5" r="2.5" fill="black"/>
    <circle cx="1341.5" cy="258.5" r="2.5" fill="black"/>
    <circle cx="1352.5" cy="258.5" r="2.5" fill="black"/>
    <circle cx="1363.5" cy="258.5" r="2.5" fill="black"/>
    <circle cx="1319.5" cy="269.5" r="2.5" fill="black"/>
    <circle cx="1330.5" cy="269.5" r="2.5" fill="black"/>
    <circle cx="1341.5" cy="269.5" r="2.5" fill="black"/>
    <circle cx="1352.5" cy="269.5" r="2.5" fill="black"/>
    <circle cx="1363.5" cy="269.5" r="2.5" fill="black"/>
    <circle cx="1319.5" cy="280.5" r="2.5" fill="black"/>
    <circle cx="1330.5" cy="280.5" r="2.5" fill="black"/>
    <circle cx="1341.5" cy="280.5" r="2.5" fill="black"/>
    <circle cx="1352.5" cy="280.5" r="2.5" fill="black"/>
    <circle cx="1363.5" cy="280.5" r="2.5" fill="black"/>
    <circle cx="1319.5" cy="291.5" r="2.5" fill="black"/>
    <circle cx="1330.5" cy="291.5" r="2.5" fill="black"/>
    <circle cx="1341.5" cy="291.5" r="2.5" fill="black"/>
    <circle cx="1352.5" cy="291.5" r="2.5" fill="black"/>
    <circle cx="1363.5" cy="291.5" r="2.5" fill="black"/>
    <circle cx="1319.5" cy="302.5" r="2.5" fill="black"/>
    <circle cx="1330.5" cy="302.5" r="2.5" fill="black"/>
    <circle cx="1341.5" cy="302.5" r="2.5" fill="black"/>
    <circle cx="1352.5" cy="302.5" r="2.5" fill="black"/>
    <circle cx="1363.5" cy="302.5" r="2.5" fill="black"/>
    <circle cx="1319.5" cy="313.5" r="2.5" fill="black"/>
    <circle cx="1330.5" cy="313.5" r="2.5" fill="black"/>
    <circle cx="1341.5" cy="313.5" r="2.5" fill="black"/>
    <circle cx="1352.5" cy="313.5" r="2.5" fill="black"/>
    <circle cx="1363.5" cy="313.5" r="2.5" fill="black"/>
    <circle cx="1319.5" cy="324.5" r="2.5" fill="black"/>
    <circle cx="1330.5" cy="324.5" r="2.5" fill="black"/>
    <circle cx="1341.5" cy="324.5" r="2.5" fill="black"/>
    <circle cx="1352.5" cy="324.5" r="2.5" fill="black"/>
    <circle cx="1363.5" cy="324.5" r="2.5" fill="black"/>
    <circle cx="1319.5" cy="335.5" r="2.5" fill="black"/>
    <circle cx="1330.5" cy="335.5" r="2.5" fill="black"/>
    <circle cx="1341.5" cy="335.5" r="2.5" fill="black"/>
    <circle cx="1352.5" cy="335.5" r="2.5" fill="black"/>
    <circle cx="1363.5" cy="335.5" r="2.5" fill="black"/>
    <path d="M1242.27 369.84C1241.77 369.84 1241.33 369.566 1241.17 369.159C1241.02 368.775 1240.6 368.517 1240.13 368.517C1240.13 368.517 1240.13 368.517 1240.13 368.517L1239.45 368.518C1239.11 368.518 1238.79 368.654 1238.58 368.886C1238.37 369.119 1238.3 369.421 1238.39 369.706C1238.85 371.238 1240.44 372.309 1242.27 372.309C1244.84 372.309 1246.93 370.499 1246.93 368.274C1246.93 365.663 1244.47 363.539 1241.46 363.539C1237.89 363.539 1234.99 366.056 1234.99 369.15C1234.99 372.846 1238.46 375.854 1242.72 375.854C1247.86 375.854 1252.04 372.232 1252.04 367.782C1252.04 365.169 1250.86 362.713 1248.73 360.865C1246.6 359.017 1243.77 358 1240.75 358C1233.17 358 1227 363.347 1227 369.918C1227 373.815 1228.75 377.479 1231.93 380.235C1234.91 382.816 1238.86 384.328 1243.05 384.493C1243.07 384.494 1243.09 384.494 1243.1 384.494C1243.38 384.494 1243.66 384.4 1243.86 384.231C1244.08 384.052 1244.2 383.804 1244.2 383.544V382.974C1244.2 382.467 1243.74 382.049 1243.16 382.025C1235.69 381.715 1229.85 376.397 1229.85 369.918C1229.85 364.708 1234.74 360.469 1240.75 360.469C1243.01 360.469 1245.12 361.23 1246.72 362.611C1248.31 363.992 1249.19 365.829 1249.19 367.782C1249.19 370.871 1246.29 373.385 1242.72 373.385C1240.03 373.385 1237.84 371.485 1237.84 369.149C1237.84 367.417 1239.46 366.008 1241.46 366.008C1242.9 366.008 1244.08 367.024 1244.08 368.274C1244.08 369.137 1243.27 369.84 1242.27 369.84Z" fill="#FFCD5C"/>
    <path d="M1274.46 369.84C1273.97 369.84 1273.52 369.566 1273.36 369.159C1273.21 368.775 1272.79 368.517 1272.33 368.517C1272.33 368.517 1272.33 368.517 1272.33 368.517L1271.64 368.518C1271.3 368.518 1270.98 368.654 1270.77 368.886C1270.57 369.119 1270.5 369.421 1270.58 369.706C1271.04 371.238 1272.64 372.309 1274.46 372.309C1277.03 372.309 1279.12 370.499 1279.12 368.274C1279.12 365.663 1276.67 363.539 1273.65 363.539C1270.08 363.539 1267.18 366.056 1267.18 369.15C1267.18 372.846 1270.65 375.854 1274.92 375.854C1280.05 375.854 1284.23 372.232 1284.23 367.782C1284.23 365.169 1283.06 362.713 1280.92 360.865C1278.79 359.017 1275.96 358 1272.94 358C1265.36 358 1259.19 363.347 1259.19 369.918C1259.19 373.815 1260.94 377.479 1264.12 380.235C1267.1 382.816 1271.05 384.328 1275.25 384.493C1275.26 384.494 1275.28 384.494 1275.3 384.494C1275.58 384.494 1275.85 384.4 1276.05 384.231C1276.27 384.052 1276.39 383.804 1276.39 383.544V382.974C1276.39 382.467 1275.93 382.049 1275.35 382.025C1267.89 381.715 1262.04 376.397 1262.04 369.918C1262.04 364.708 1266.93 360.469 1272.94 360.469C1275.2 360.469 1277.32 361.23 1278.91 362.611C1280.5 363.992 1281.38 365.829 1281.38 367.782C1281.38 370.871 1278.48 373.385 1274.92 373.385C1272.22 373.385 1270.03 371.485 1270.03 369.149C1270.03 367.417 1271.66 366.008 1273.65 366.008C1275.1 366.008 1276.27 367.024 1276.27 368.274C1276.27 369.137 1275.46 369.84 1274.46 369.84Z" fill="#FFCD5C"/>
    <path d="M1306.66 369.84C1306.16 369.84 1305.72 369.566 1305.56 369.159C1305.4 368.775 1304.99 368.517 1304.52 368.517C1304.52 368.517 1304.52 368.517 1304.52 368.517L1303.83 368.518C1303.49 368.518 1303.17 368.654 1302.97 368.886C1302.76 369.119 1302.69 369.421 1302.77 369.706C1303.23 371.238 1304.83 372.309 1306.66 372.309C1309.22 372.309 1311.31 370.499 1311.31 368.274C1311.31 365.663 1308.86 363.539 1305.85 363.539C1302.28 363.539 1299.37 366.056 1299.37 369.15C1299.37 372.846 1302.84 375.854 1307.11 375.854C1312.24 375.854 1316.42 372.232 1316.42 367.782C1316.42 365.169 1315.25 362.713 1313.12 360.865C1310.99 359.017 1308.15 358 1305.14 358C1297.55 358 1291.38 363.347 1291.38 369.918C1291.38 373.815 1293.14 377.479 1296.31 380.235C1299.29 382.816 1303.24 384.328 1307.44 384.493C1307.45 384.494 1307.47 384.494 1307.49 384.494C1307.77 384.494 1308.04 384.4 1308.24 384.231C1308.46 384.052 1308.58 383.804 1308.58 383.544V382.974C1308.58 382.467 1308.12 382.049 1307.54 382.025C1300.08 381.715 1294.23 376.397 1294.23 369.918C1294.23 364.708 1299.12 360.469 1305.14 360.469C1307.39 360.469 1309.51 361.23 1311.1 362.611C1312.7 363.992 1313.57 365.829 1313.57 367.782C1313.57 370.871 1310.67 373.385 1307.11 373.385C1304.41 373.385 1302.22 371.485 1302.22 369.149C1302.22 367.417 1303.85 366.008 1305.85 366.008C1307.29 366.008 1308.46 367.024 1308.46 368.274C1308.46 369.137 1307.65 369.84 1306.66 369.84Z" fill="#FFCD5C"/>
    <path d="M1338.85 369.84C1338.35 369.84 1337.91 369.566 1337.75 369.159C1337.6 368.775 1337.18 368.517 1336.71 368.517C1336.71 368.517 1336.71 368.517 1336.71 368.517L1336.03 368.518C1335.69 368.518 1335.37 368.654 1335.16 368.886C1334.95 369.119 1334.88 369.421 1334.96 369.706C1335.42 371.238 1337.02 372.309 1338.85 372.309C1341.41 372.309 1343.5 370.499 1343.5 368.274C1343.5 365.663 1341.05 363.539 1338.04 363.539C1334.47 363.539 1331.56 366.056 1331.56 369.15C1331.57 372.846 1335.04 375.854 1339.3 375.854C1344.44 375.854 1348.61 372.232 1348.62 367.782C1348.62 365.169 1347.44 362.713 1345.31 360.865C1343.18 359.017 1340.34 358 1337.33 358C1329.75 358 1323.58 363.347 1323.58 369.918C1323.58 373.815 1325.33 377.479 1328.51 380.235C1331.49 382.816 1335.44 384.328 1339.63 384.493C1339.65 384.494 1339.66 384.494 1339.68 384.494C1339.96 384.494 1340.23 384.4 1340.44 384.231C1340.65 384.052 1340.78 383.804 1340.78 383.544V382.974C1340.78 382.467 1340.32 382.049 1339.73 382.025C1332.27 381.715 1326.43 376.397 1326.43 369.918C1326.43 364.708 1331.32 360.469 1337.33 360.469C1339.58 360.469 1341.7 361.23 1343.3 362.611C1344.89 363.992 1345.77 365.829 1345.77 367.782C1345.77 370.871 1342.87 373.385 1339.3 373.385C1336.61 373.385 1334.41 371.485 1334.41 369.149C1334.41 367.417 1336.04 366.008 1338.04 366.008C1339.48 366.008 1340.65 367.024 1340.65 368.274C1340.65 369.137 1339.84 369.84 1338.85 369.84Z" fill="#FFCD5C"/>
    <path d="M1371.04 369.84C1370.54 369.84 1370.1 369.566 1369.94 369.159C1369.79 368.775 1369.37 368.517 1368.9 368.517C1368.9 368.517 1368.9 368.517 1368.9 368.517L1368.22 368.518C1367.88 368.518 1367.56 368.654 1367.35 368.886C1367.14 369.119 1367.07 369.421 1367.16 369.706C1367.62 371.238 1369.21 372.309 1371.04 372.309C1373.61 372.309 1375.7 370.499 1375.7 368.274C1375.7 365.663 1373.24 363.539 1370.23 363.539C1366.66 363.539 1363.76 366.056 1363.76 369.15C1363.76 372.846 1367.23 375.854 1371.49 375.854C1376.63 375.854 1380.81 372.232 1380.81 367.782C1380.81 365.169 1379.63 362.713 1377.5 360.865C1375.37 359.017 1372.54 358 1369.52 358C1361.94 358 1355.77 363.347 1355.77 369.918C1355.77 373.815 1357.52 377.479 1360.7 380.235C1363.68 382.816 1367.63 384.328 1371.82 384.493C1371.84 384.494 1371.86 384.494 1371.87 384.494C1372.15 384.494 1372.43 384.4 1372.63 384.231C1372.85 384.052 1372.97 383.804 1372.97 383.544V382.974C1372.97 382.467 1372.51 382.049 1371.93 382.025C1364.46 381.715 1358.62 376.397 1358.62 369.918C1358.62 364.708 1363.51 360.469 1369.52 360.469C1371.78 360.469 1373.89 361.23 1375.49 362.611C1377.08 363.992 1377.96 365.829 1377.96 367.782C1377.96 370.871 1375.06 373.385 1371.49 373.385C1368.8 373.385 1366.61 371.485 1366.61 369.149C1366.61 367.417 1368.23 366.008 1370.23 366.008C1371.67 366.008 1372.85 367.024 1372.85 368.274C1372.85 369.137 1372.04 369.84 1371.04 369.84Z" fill="#FFCD5C"/>
    <path d="M1403.23 369.84C1402.74 369.84 1402.29 369.566 1402.13 369.159C1401.98 368.775 1401.56 368.517 1401.1 368.517C1401.1 368.517 1401.1 368.517 1401.1 368.517L1400.41 368.518C1400.07 368.518 1399.75 368.654 1399.54 368.886C1399.34 369.119 1399.26 369.421 1399.35 369.706C1399.81 371.238 1401.41 372.309 1403.23 372.309C1405.8 372.309 1407.89 370.499 1407.89 368.274C1407.89 365.663 1405.44 363.539 1402.42 363.539C1398.85 363.539 1395.95 366.056 1395.95 369.15C1395.95 372.846 1399.42 375.854 1403.69 375.854C1408.82 375.854 1413 372.232 1413 367.782C1413 365.169 1411.83 362.713 1409.69 360.865C1407.56 359.017 1404.73 358 1401.71 358C1394.13 358 1387.96 363.347 1387.96 369.918C1387.96 373.815 1389.71 377.479 1392.89 380.235C1395.87 382.816 1399.82 384.328 1404.02 384.493C1404.03 384.494 1404.05 384.494 1404.06 384.494C1404.35 384.494 1404.62 384.4 1404.82 384.231C1405.04 384.052 1405.16 383.804 1405.16 383.544V382.974C1405.16 382.467 1404.7 382.049 1404.12 382.025C1396.66 381.715 1390.81 376.397 1390.81 369.918C1390.81 364.708 1395.7 360.469 1401.71 360.469C1403.97 360.469 1406.09 361.23 1407.68 362.611C1409.27 363.992 1410.15 365.829 1410.15 367.782C1410.15 370.871 1407.25 373.385 1403.69 373.385C1400.99 373.385 1398.8 371.485 1398.8 369.149C1398.8 367.417 1400.42 366.008 1402.42 366.008C1403.87 366.008 1405.04 367.024 1405.04 368.274C1405.04 369.137 1404.23 369.84 1403.23 369.84Z" fill="#FFCD5C"/>
    <path d="M329 208C329 310.725 255.351 394 164.5 394C73.6492 394 0 310.725 0 208C0 105.275 73.6492 22 164.5 22C255.351 22 329 105.275 329 208ZM65.4199 208C65.4199 269.872 109.78 320.03 164.5 320.03C219.22 320.03 263.58 269.872 263.58 208C263.58 146.128 219.22 95.9702 164.5 95.9702C109.78 95.9702 65.4199 146.128 65.4199 208Z" fill="#0193D9"/>
    <path d="M522.34 381C497.515 381 473.725 377.283 450.969 369.848V369.848C415.227 357.64 405.244 316.494 419.097 281.356L420.381 278.098C433.68 288.912 449.344 297.867 467.371 304.964C485.694 311.723 504.165 315.102 522.784 315.102C536.969 315.102 548.347 313.582 556.918 310.54C565.784 307.161 572.285 302.599 576.423 296.854C580.56 291.109 582.629 284.519 582.629 277.084C582.629 267.622 579.378 260.187 572.876 254.78C566.375 249.035 557.804 244.473 547.165 241.094C536.526 237.377 524.704 233.997 511.701 230.956C498.993 227.576 486.137 223.521 473.134 218.79C460.426 214.059 448.753 207.976 438.113 200.541C427.474 193.107 418.756 183.307 411.959 171.141C405.457 158.975 402.206 143.43 402.206 124.506C402.206 104.229 406.935 85.8117 416.392 69.2527C426.144 52.3559 440.625 39.0073 459.835 29.2072C479.34 19.0691 503.722 14 532.979 14C552.485 14 571.694 16.7035 590.608 22.1105V22.1105C622.419 30.6358 633.121 66.7517 622.089 97.7822L618.536 107.778C604.055 98.3154 589.574 91.3877 575.093 86.9945C560.612 82.2633 546.426 79.8978 532.536 79.8978C518.646 79.8978 507.268 81.7564 498.402 85.4737C489.536 89.1911 483.182 94.0912 479.34 100.174C475.498 105.919 473.577 112.678 473.577 120.45C473.577 129.575 476.828 137.009 483.33 142.754C489.832 148.161 498.402 152.554 509.041 155.934C519.68 159.313 531.354 162.692 544.062 166.072C557.065 169.451 569.921 173.337 582.629 177.731C595.632 182.124 607.454 188.038 618.093 195.472C628.732 202.907 637.302 212.707 643.804 224.873C650.601 237.039 654 252.415 654 271.001C654 290.94 649.124 309.188 639.371 325.747C629.619 342.306 614.99 355.655 595.485 365.793C576.275 375.931 551.893 381 522.34 381Z" fill="#0193D9" fill-opacity="0.69"/>
    <path d="M461.096 235.943L471.159 223.458L461.921 211.158L472.081 195.781L463.33 181.429L472.236 172.214L464.798 156.252L474.518 141.481L465.118 127.728L473.492 115.6L465.916 103.786L474.975 89.9572L466.408 76.3974L475.329 60.4224" stroke="#FFCD5C" stroke-width="2" stroke-linecap="round"/>
    <path d="M468.709 238.766L478.773 226.281L469.535 213.981L479.695 198.604L470.944 184.252L479.85 175.037L472.412 159.075L482.132 144.304L472.732 130.551L481.106 118.423L473.529 106.609L482.589 92.7798L474.022 79.22L482.943 63.245" stroke="#FFCD5C" stroke-width="2" stroke-linecap="round"/>
    <path d="M477.207 240.762L487.271 228.277L478.032 215.976L488.192 200.6L479.442 186.248L488.348 177.033L480.909 161.07L490.629 146.3L481.23 132.546L489.604 120.419L482.027 108.605L491.087 94.7755L482.519 81.2157L491.441 65.2407" stroke="#FFCD5C" stroke-width="2" stroke-linecap="round"/>
    <path d="M485.705 242.757L495.769 230.272L486.53 217.972L496.69 202.595L487.94 188.244L496.846 179.028L489.407 163.066L499.127 148.295L489.728 134.542L498.102 122.414L490.525 110.601L499.585 96.7712L491.017 83.2114L499.939 67.2364" stroke="#FFCD5C" stroke-width="2" stroke-linecap="round"/>
    <path d="M452.67 234.113L462.733 221.629L453.495 209.328L463.655 193.952L454.905 179.6L463.811 170.385L456.372 154.422L466.092 139.652L456.693 125.898L465.066 113.771L457.49 101.957L466.549 88.1274L457.982 74.5676L466.904 58.5927" stroke="#FFCD5C" stroke-width="2" stroke-linecap="round"/>
    <path d="M443.22 234.742L453.283 222.257L444.045 209.956L454.205 194.58L445.454 180.228L454.36 171.013L446.922 155.051L456.642 140.28L447.242 126.527L455.616 114.399L448.04 102.585L457.099 88.7557L448.532 75.1959L457.453 59.2209" stroke="#FFCD5C" stroke-width="2" stroke-linecap="round"/>
    <path d="M593.218 183.771L583.349 197.543L593.831 209.966L584.033 226.795L594.123 241.412L585.279 251.665L594.081 268.059L584.711 284.223L595.45 298.169L587.342 311.463L596.041 323.472L587.312 338.603L597.16 352.395L588.705 369.781" stroke="#0E6189" stroke-width="2" stroke-linecap="round"/>
    <path d="M585.018 181.249L575.148 195.021L585.63 207.444L575.832 224.273L585.922 238.889L577.078 249.143L585.88 265.537L576.51 281.701L587.249 295.647L579.141 308.941L587.841 320.95L579.111 336.081L588.96 349.873L580.504 367.259" stroke="#0E6189" stroke-width="2" stroke-linecap="round"/>
    <path d="M575.934 179.652L566.064 193.425L576.546 205.848L566.748 222.676L576.838 237.293L567.994 247.547L576.796 263.94L567.426 280.104L578.165 294.05L570.057 307.344L578.757 319.354L570.027 334.484L579.876 348.276L571.42 365.662" stroke="#0E6189" stroke-width="2" stroke-linecap="round"/>
    <path d="M566.85 178.055L556.981 191.828L567.463 204.251L557.665 221.079L567.755 235.696L558.911 245.95L567.713 262.344L558.343 278.507L569.081 292.453L560.974 305.747L569.673 317.757L560.943 332.887L570.792 346.679L562.337 364.065" stroke="#0E6189" stroke-width="2" stroke-linecap="round"/>
    <path d="M602.216 185.197L592.347 198.969L602.828 211.393L593.031 228.221L603.12 242.838L594.277 253.091L603.079 269.485L593.709 285.649L604.447 299.595L596.339 312.889L605.039 324.899L596.309 340.029L606.158 353.821L597.703 371.207" stroke="#0E6189" stroke-width="2" stroke-linecap="round"/>
    <path d="M612.148 183.969L602.279 197.741L612.76 210.164L602.963 226.993L613.052 241.609L604.209 251.863L613.011 268.257L603.641 284.421L614.379 298.367L606.271 311.661L614.971 323.67L606.241 338.801L616.09 352.593L607.635 369.979" stroke="#0E6189" stroke-width="2" stroke-linecap="round"/>
    <mask id="mask1_23_2314"   maskUnits="userSpaceOnUse" x="42" y="83" width="245" height="258">
    <rect x="42" y="83" width="245" height="15.1235" fill="#FFCD5C"/>
    <rect x="42" y="110.222" width="245" height="15.1235" fill="#FFCD5C"/>
    <rect x="42" y="137.444" width="245" height="15.1235" fill="#FFCD5C"/>
    <rect x="42" y="164.667" width="245" height="15.1235" fill="#FFCD5C"/>
    <rect x="42" y="191.889" width="245" height="15.1235" fill="#FFCD5C"/>
    <rect x="42" y="219.111" width="245" height="15.1235" fill="#FFCD5C"/>
    <rect x="42" y="246.333" width="245" height="15.1235" fill="#FFCD5C"/>
    <rect x="42" y="273.556" width="245" height="15.1235" fill="#FFCD5C"/>
    <rect x="42" y="297.753" width="245" height="15.1235" fill="#FFCD5C"/>
    <rect x="42" y="324.975" width="245" height="15.1235" fill="#FFCD5C"/>
    </mask>
    <g mask="url(#mask1_23_2314)">
    <ellipse cx="164.501" cy="211.549" rx="106.887" ry="134.599" fill="#FFCD5C"/>
    </g>
    </svg>
    
  );
}

export default UnSvgRandom;
