import React from "react";
import "../Assets/scss/home.scss";
import UnSvgRandom from "../Components/UnSvgRandom";
import CountUp from "react-countup";
import Footer from "../Components/Footer";
function Home() {
  return (
    <>
      <section className="landing">
        <div className="center_text">
          <h1>
            Organizația Studenților din Facultatea de Inginerie Industrială și
            Robotică
          </h1>
          <UnSvgRandom />
          <div className="button">
            <a href="#">See more</a>
          </div>
        </div>
      </section>
      <section className="bout">
        <div className="texts">
          <h3>ABOUT US</h3>
          <h2>Cine suntem?</h2>
          <p className="p">
            <span>OSFIIR</span> este o organizație apolitică si
            nonguvernamentală ce are ca scop promovarea inițiativelor culturale
            si civice ale studenților și de asemenea să păzească drepturile lor.
            Din 2006, facem parte de asemenea din Uniunea Nationala a
            Studenților din România și suntem membri activi ai acestei
            Federații.
          </p>
        </div>
      </section>
      <div className="board">
        <h1>BOARD</h1>
        <div className="cards">
          <div className="card">
            <div className="img">
              <img src={require("../Assets/images/andrei.png")} alt="" />
            </div>
            <h2>Diaconu Andrei</h2>
            <h3>Președinte</h3>
          </div>{" "}
          <div className="card">
            <div className="img">
              <img src={require("../Assets/images/alina.png")} alt="" />
            </div>
            <h2>Epure Alina</h2>
            <h3>Vicepreședinte</h3>
          </div>{" "}
          <div className="card">
            <div className="img">
              <img src={require("../Assets/images/tibi.png")} alt="" />
            </div>
            <h2>Șutu Tiberiu</h2>
            <h3>Vicepreședinte Intern</h3>
          </div>{" "}
          <div className="card">
            <div className="img">
              <img src={require("../Assets/images/hanga.png")} alt="" />
            </div>
            <h2>Hanga Mihail</h2>
            <h3>Secretar General</h3>
          </div>{" "}
          <div className="card">
            <div className="img">
              <img src={require("../Assets/images/raducu.png")} alt="" />
            </div>
            <h2>Aciobăniței Radu</h2>
            <h3>Coordonator HR</h3>
          </div>
          <div className="card">
            <div className="img">
              <img src={require("../Assets/images/robert.png")} alt="" />
            </div>
            <h2>Durlan Robert</h2>
            <h3>Coordonator PR</h3>
          </div>
          <div className="card">
            <div className="img">
              {/* <img src={require("../Assets/images/denisa.png")} alt="" /> */}
            </div>
            <h2>Stanciu Denisa</h2>
            <h3>Coordonator Fundraising</h3>
          </div>{" "}
          <div className="card">
            <div className="img">
              <img src={require("../Assets/images/radu.png")} alt="" />
            </div>
            <h2>Andrei Radu</h2>
            <h3>Coordonator Vocațional</h3>{" "}
          </div>
          <div className="card">
            <div className="img">
              <img src={require("../Assets/images/carmen.png")} alt="" />
            </div>
            <h2>Șelaru Carmen</h2>
            <h3>Coordonator Tehnic</h3>
          </div>
        </div>
        <div className="numbers">
          <div className="number">
            <h2>Ani:</h2>
            <CountUp
              end={17}
              redraw={true}
              enableScrollSpy
              scrollSpyDelay={600}
            />
          </div>
          <div className="number">
            <h2>Membrii:</h2>
            <CountUp
              end={33}
              redraw={true}
              enableScrollSpy
              scrollSpyDelay={600}
            />
          </div>
          <div className="number">
            <h2>Voluntari:</h2>
            <span>
              <CountUp
                end={200}
                redraw={true}
                enableScrollSpy
                scrollSpyDelay={600}
              />
              +
            </span>
          </div>
        </div>
      </div>
      <Footer />
   
    </>
  );
}

export default Home;
