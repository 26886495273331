import React from "react";
import "../Assets/scss/footer.scss";

function Footer() {
  return (
    <footer>
      <div className="top">
        <div className="left">
          <div className="logo">
            <img src={require("../Assets/images/osfiir_ok.svg").default} alt="" />
            {/* <img
                src={require("../assets/images/text_logo.svg").default}
                alt=""
              /> */}
          </div>
          <p className="">
          Vă invităm cu un like pe paginile noastre de Facebook și Instagram!
          </p>
        </div>
        <div className="center">
          <img src={require("../Assets/images/fiirlogo.png")} alt="" />
          <img src={require("../Assets/images/unsr.png")} alt="" />
        </div>
        <div className="right">
          <div className="l">
            <h3 className="title">Quick Links</h3>
            <a href="#about" className="">
              About
            </a>
            <a href="#roadmap" className="">
              Roadmap
            </a>
            <a
              target="_blank"
              href="#"
              className=""
            >
              FAQs
            </a>
          </div>
           <div className="r">
            <h3 className="title">Community</h3>
            <a href="#" className="">
              How it works!
            </a>
            <a href="#" className="">
              Get in touch
            </a>
          </div>
        </div>
      </div>
      <div className="down">
        <p className="">Copyrights © 2023 OSFIIR All Rights Reserved.</p>
        <div className="s">
          <p className="">Privacy policy</p>
          <p className="">Terms of Use</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
